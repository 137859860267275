import { cloneFnJSON } from '@vueuse/core'

export function useVModelProps<T extends Record<string, unknown>>(props: T) {
  const instance = getCurrentInstance()
  if (!instance) throw new Error('useVModelReactive must be called inside of setup()')

  let isUpdating = false

  const model = ref(cloneFnJSON(toRaw(props))) as Ref<T>
  watch(
    () => props,
    (newProps) => {
      if (isUpdating) return
      isUpdating = true

      model.value = cloneFnJSON(newProps)
      void nextTick(() => {
        isUpdating = false
      })
    },
    { deep: true },
  )

  watch(
    model,
    (newModel) => {
      if (isUpdating) return
      instance.emit('update:props', cloneFnJSON(newModel))
    },
    { deep: true },
  )

  return model
}
