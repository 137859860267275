<script lang="ts" setup>
import { useImage } from '@vueuse/core'
import { twMerge } from 'tailwind-merge'

import { PUBLIC_IMAGES_BUCKET_NAME } from '~/utils/constants'

const props = defineProps<{
  modelValue: {
    src: string
    alt: string
  }
  showDeleteButton?: boolean
  editorClass?: string
  editorStyle?: string
}>()

const emit = defineEmits<{
  'delete': []
  'update:modelValue': [src: string, alt: string]
}>()

const editMode = useEditMode()
const image = useVModel(props, 'modelValue', emit)

const { error } = useImage(() => ({
  src: image.value.src,
}))

const open = ref(false)

const user = useUser()
</script>

<template>
  <div
    :class="twMerge('group/image-edit grid grid-cols-1 grid-rows-1', editorClass ?? '')"
    :style="editorStyle"
  >
    <AlMediaSelectModal
      v-if="open && editMode"
      v-model:open="open"
      v-model="image"
      :bucket-name="PUBLIC_IMAGES_BUCKET_NAME"
      :folder-name="user.id"
    />
    <button
      v-if="editMode"
      class="relative h-full w-full bg-stone-600/70 opacity-0 transition-opacity [grid-area:1/1] group-hover/image-edit:opacity-100"
      @click="() => (open = true)"
    >
      <div class="flex flex-col items-center gap-2">
        <UIcon name="i-ph-upload-simple" class="h-16 w-16 text-stone-100" />
        <UButton
          v-if="showDeleteButton"
          icon="i-ph-trash-fill"
          color="red"
          label="Löschen"
          class="z-10"
          @click.stop="() => emit('delete')"
        />
      </div>
      <div class="absolute inset-2 border-2 border-dashed border-stone-100" />
    </button>
    <img
      v-if="!error && image.src"
      :src="image.src"
      :class="{ 'cursor-pointer': editMode }"
      class="h-full max-h-[clamp(30rem,40vw,50vh)] object-contain [grid-area:1/1]"
    />
    <div v-else class="grid h-full w-full place-items-center bg-stone-500">
      <UIcon
        name="i-ph-image-fill"
        class="m-20 h-20 w-20 text-stone-100 group-hover/image-edit:invisible"
      />
    </div>
  </div>
</template>
