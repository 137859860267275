<script lang="ts" setup>
import VueDatePicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const props = defineProps<VueDatePickerProps>()

const range = defineModel<Date[] | null>({ default: null })
</script>

<template>
  <div class="relative">
    <VueDatePicker
      v-bind="props"
      v-model="range"
      range
      :enable-time-picker="false"
      locale="de"
      cancel-text="Abbrechen"
      select-text="Auswählen"
      placeholder="Datum"
      format="dd.MM"
    >
      <template #action-preview />
      <template #input-icon>
        <div class="flex items-center">
          <UIcon name="i-ph-calendar-blank" />
        </div>
      </template>
    </VueDatePicker>
    <span
      v-if="!range"
      class="pointer-events-none absolute inset-y-0 end-0 flex items-center px-2.5"
    >
      <UIcon name="i-heroicons-chevron-down-20-solid" class="text-gray-400" />
    </span>
  </div>
</template>

<style scoped lang="postcss">
:deep(.dp__theme_light) {
  --dp-text-color: theme('colors.al-gray.DEFAULT');
  --dp-primary-color: theme('colors.al-primary.400');
  --dp-primary-text-color: theme('colors.white');
  --dp-border-color: transparent;
  --dp-border-color-hover: transparent;
}

:deep(.dp__input) {
  @apply placeholder:text-gray-400 placeholder:opacity-100;
}

:deep() {
  --dp-text-color: theme('colors.al-gray.DEFAULT');
  --dp-font-family: 'Martel Sans', sans-serif;
  --dp-border-radius: 0px; /*Configurable border-radius*/
  --dp-cell-border-radius: 0px; /*Specific border radius for the calendar cell*/
  --dp-icon-color: theme('colors.gray.400');
  --dp-input-icon-padding: 1.5rem;
  --dp-font-size: theme('fontSize.sm');
}
</style>
