<script setup lang="ts">
const props = defineProps<{
  title: string
  infos: {
    text: string
  }[]
}>()

const { model, editMode } = useCmsMode(props)
</script>

<template>
  <AlPadding>
    <div class="mb-10">
      <AlCmsInlineText v-model="model.title">
        <h3 class="font-sans-header text-3xl uppercase text-al-primary">{{ title }}</h3>
      </AlCmsInlineText>
    </div>

    <div class="grid gap-x-16 gap-y-8 sm:px-6 lg:grid-cols-2">
      <div
        v-for="(info, idx) in model.infos"
        :key="idx"
        class="flex items-start gap-6 break-all sm:items-center"
      >
        <div
          class="relative size-8 flex-shrink-0 rounded-full bg-gray-100 p-1.5 text-al-secondary sm:size-12 sm:p-2.5"
        >
          <UIcon class="h-full w-full" name="i-heroicons-paint-brush" />
        </div>

        <AlCmsRichText v-model="info.text" class="prose-lg font-light" />

        <UButton
          v-if="editMode"
          icon="i-ph-trash-fill"
          color="red"
          @click="() => model.infos.splice(idx, 1)"
        />
      </div>
      <AlDashedOutlineButton
        v-if="editMode"
        @click="
          () =>
            model.infos.push({
              text: 'Neue Information',
            })
        "
      />
    </div>
  </AlPadding>
</template>
