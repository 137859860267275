<script setup lang="ts">
const props = defineProps<{
  modelValue: string
  placeholder?: string
}>()

const model = useVModel(props, 'modelValue')

const editMode = useEditMode()

const richTextClass = /* tw */ 'prose max-w-[100ch]'
</script>

<template>
  <AlCmsRichTextEditor v-if="editMode" v-bind="props" v-model="model" :class="[richTextClass]" />
  <div
    v-else
    class="richtext-view whitespace-break-spaces"
    :class="[richTextClass]"
    v-html="modelValue"
  />
</template>

<style scoped>
.richtext-view :deep(p:empty::after) {
  content: '\00A0';
}
</style>
