<script lang="ts" setup>
type Image = {
  src: string
  alt: string
}

type Stat = {
  label: string
  value: string
}

const props = defineProps<{
  images: [Image, Image, Image, Image]
  stats: [Stat, Stat, Stat]
}>()

const { model } = useCmsMode(props)

const INLINE_TEXT_EDITOR_CLASS = /* tw */ '-outline-offset-2 !outline-white'
const IMAGE_EDITOR_CLASS = /* tw */ 'rounded-full object-cover'
</script>

<template>
  <AlPadding side="none">
    <!-- mobile -->
    <svg class="mx-auto max-w-xs md:hidden" viewBox="0 0 324 1575">
      <g transform="translate(-36)">
        <g transform="translate(-666)">
          <path
            d="M3847.958,2446.362s.3-288.982,232.364-295.436,394.086,62.292,556.352-107.967,90.358-386.589,240.468-430.014"
            transform="matrix(0.629, -0.777, 0.777, 0.629, -3485.277, 2775.19)"
            fill="none"
            stroke="#eac072"
            stroke-width="2.5"
          />
          <g transform="translate(-280.629 -1467)">
            <foreignObject width="403.5" height="269" transform="translate(977.548 1466.404)">
              <div class="grid place-items-center" style="width: 404px; height: 269px">
                <AlCmsImageEditor
                  v-model="model.images[0]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-207.425 -332)">
            <foreignObject width="345.859" height="233" transform="translate(878.656 1675.041)">
              <div class="grid items-end pl-11" style="width: 346px; height: 233px">
                <AlCmsImageEditor
                  v-model="model.images[3]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(28.824 -1142)">
            <foreignObject width="249.737" height="374.538" transform="translate(732.176 1458.007)">
              <div class="grid place-items-center" style="width: 250px; height: 375px">
                <AlCmsImageEditor
                  v-model="model.images[1]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 244px; height: 244px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(605 460)">
            <circle cx="122" cy="122" r="122" transform="translate(156 720)" fill="#eac072" />
            <foreignObject
              transform="translate(175 801)"
              class="text-center"
              width="208"
              height="64"
              color="#fff"
              font-size="40"
              font-family="SecularOne-Regular, Secular One"
            >
              <AlCmsInlineText v-model="model.stats[2].value" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[2].value }}</span>
              </AlCmsInlineText>
            </foreignObject>
            <foreignObject
              transform="translate(175 863)"
              class="text-center"
              width="208"
              height="40"
              color="#fff"
              font-size="25"
              font-family="MartelSans-Regular, Martel Sans"
            >
              <AlCmsInlineText v-model="model.stats[2].label" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[2].label }}</span>
              </AlCmsInlineText>
            </foreignObject>
          </g>
          <g transform="translate(156.449 250.449)">
            <circle
              cx="151.5"
              cy="151.5"
              r="151.5"
              transform="translate(545.551 406.551)"
              fill="#eac072"
            />
            <foreignObject
              transform="translate(570 500)"
              class="text-center"
              width="256"
              height="72"
              color="#fff"
              font-size="45"
              font-family="SecularOne-Regular, Secular One"
            >
              <AlCmsInlineText v-model="model.stats[1].value" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[1].value }}</span>
              </AlCmsInlineText>
            </foreignObject>
            <foreignObject
              transform="translate(570 563)"
              class="text-center"
              width="256"
              height="40"
              color="#fff"
              font-size="25"
              font-family="MartelSans-Regular, Martel Sans"
            >
              <AlCmsInlineText v-model="model.stats[1].label" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[1].label }}</span>
              </AlCmsInlineText>
            </foreignObject>
          </g>
          <g transform="translate(286)">
            <circle cx="89.5" cy="89.5" r="89.5" transform="translate(416 258)" fill="#eac072" />
            <foreignObject
              transform="translate(435 297)"
              class="text-center"
              width="144"
              height="72"
              color="#fff"
              font-size="45"
              font-family="SecularOne-Regular, Secular One"
            >
              <AlCmsInlineText v-model="model.stats[0].value" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[0].value }}</span>
              </AlCmsInlineText>
            </foreignObject>
            <foreignObject
              transform="translate(435 353)"
              class="text-center"
              width="144"
              height="40"
              color="#fff"
              font-size="25"
              font-family="MartelSans-Regular, Martel Sans"
            >
              <AlCmsInlineText v-model="model.stats[0].label" :class="INLINE_TEXT_EDITOR_CLASS">
                <span>{{ stats[0].label }}</span>
              </AlCmsInlineText>
            </foreignObject>
          </g>
          <g transform="translate(-383.629 -624)">
            <foreignObject width="366" height="244" transform="translate(1023.104 1526)">
              <div class="grid place-items-center" style="width: 366px; height: 244px">
                <AlCmsImageEditor
                  v-model="model.images[2]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 244px; height: 244px"
                />
              </div>
            </foreignObject>
          </g>
        </g>
      </g>
    </svg>

    <!-- tablet -->
    <svg class="hidden md:block lg:hidden" viewBox="0 0 960 844">
      <g transform="translate(0 -120)">
        <g transform="translate(-58)">
          <path
            d="M3747.538,2010.214s183.971-114.115,332.264-50.632,394.606,253.636,556.872,83.377,90.358-386.589,240.468-430.014"
            transform="matrix(0.995, -0.105, 0.105, 0.995, -3967.939, -989.666)"
            fill="none"
            stroke="#eac072"
            stroke-width="2.5"
          />
          <g transform="translate(-409.629 -806)">
            <rect
              width="366"
              height="244"
              transform="translate(1023.104 1526)"
              fill="url(#pattern)"
            />
          </g>
          <g transform="translate(-365.629 -1389)">
            <foreignObject width="403.5" height="269" transform="translate(977.548 1466.404)">
              <div class="grid items-end justify-center" style="width: 404px; height: 269px">
                <AlCmsImageEditor
                  v-model="model.images[1]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-623.425 -1233)">
            <foreignObject width="345.859" height="233" transform="translate(878.656 1675.041)">
              <div class="grid items-center" style="width: 346px; height: 233px">
                <AlCmsImageEditor
                  v-model="model.images[2]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-576.176 -1389)">
            <foreignObject width="249.737" height="374.538" transform="translate(732.176 1458.007)">
              <div class="grid place-items-center" style="width: 250px; height: 375px">
                <AlCmsImageEditor
                  v-model="model.images[0]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 244px; height: 244px"
                />
              </div>
            </foreignObject>
          </g>
          <circle cx="154.5" cy="154.5" r="154.5" transform="translate(546 407)" fill="#eac072" />
          <circle cx="89.5" cy="89.5" r="89.5" transform="translate(416 258)" fill="#eac072" />
          <circle cx="122" cy="122" r="122" transform="translate(156 720)" fill="#eac072" />
          <foreignObject
            transform="translate(175 801)"
            class="text-center"
            width="208"
            height="64"
            color="#fff"
            font-size="40"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[2].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(175 863)"
            class="text-center"
            width="208"
            height="40"
            color="#fff"
            font-size="25"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[2].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(575 502)"
            class="text-center"
            width="256"
            height="72"
            color="#fff"
            font-size="45"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[1].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(575 567)"
            class="text-center"
            width="256"
            height="40"
            color="#fff"
            font-size="25"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[1].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(435 297)"
            class="text-center"
            width="144"
            height="72"
            color="#fff"
            font-size="45"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[0].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[0].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(435 353)"
            class="text-center"
            width="144"
            height="40"
            color="#fff"
            font-size="25"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[0].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[0].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
        </g>
      </g>
    </svg>

    <!-- desktop -->
    <svg class="hidden lg:block xl:hidden" viewBox="0 0 1366 1017">
      <g transform="translate(0 -1406)">
        <g>
          <path
            d="M3747.538,2155.124s251.078-155.74,453.462-69.1,538.545,346.154,760,113.79,123.318-527.6,328.183-586.868"
            transform="matrix(0.995, -0.105, 0.105, 0.995, -4045.347, 354.341)"
            fill="none"
            stroke="#eac072"
            stroke-width="2.5"
          />
          <g transform="translate(-414.629 588)">
            <foreignObject width="463.5" height="309" transform="translate(1006.448 1526)">
              <div class="grid place-items-center" style="width: 464px; height: 309px">
                <AlCmsImageEditor
                  v-model="model.images[3]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 309px; height: 309px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-259.629 24)">
            <foreignObject width="403.5" height="269" transform="translate(977.548 1466.404)">
              <div class="grid place-items-center" style="width: 404px; height: 269px">
                <AlCmsImageEditor
                  v-model="model.images[1]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-465.425 215)">
            <foreignObject width="345.859" height="233" transform="translate(878.656 1675.041)">
              <div class="grid place-items-center" style="width: 346px; height: 233px">
                <AlCmsImageEditor
                  v-model="model.images[2]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(-607.176 109)">
            <foreignObject width="267.136" height="400.632" transform="translate(732.176 1453.27)">
              <div class="grid place-items-center" style="width: 267px; height: 401px">
                <AlCmsImageEditor
                  v-model="model.images[0]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 261px; height: 261px"
                />
              </div>
            </foreignObject>
          </g>
          <circle cx="171.5" cy="171.5" r="171.5" transform="translate(898 1771)" fill="#eac072" />
          <circle cx="91.5" cy="91.5" r="91.5" transform="translate(481 1667)" fill="#eac072" />
          <circle cx="138.5" cy="138.5" r="138.5" transform="translate(125 2120)" fill="#eac072" />
          <foreignObject
            transform="translate(160 2214)"
            class="text-center"
            width="208"
            height="64"
            color="#fff"
            font-size="40"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[2].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            class="text-center"
            transform="translate(160 2276)"
            color="#fff"
            font-size="25"
            width="208"
            height="64"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[2].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(950 1895)"
            class="text-center"
            width="240"
            height="72"
            color="#fff"
            font-size="45"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[1].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            width="240"
            height="96"
            class="text-center"
            transform="translate(950 1960)"
            font-size="25"
            color="#fff"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[1].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(500 1710)"
            class="text-center"
            color="#fff"
            width="144"
            height="72"
            font-size="45"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[0].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[0].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            class="text-center"
            transform="translate(500 1766)"
            color="#fff"
            font-size="25"
            width="144"
            height="40"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[0].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[0].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
        </g>
      </g>
    </svg>

    <!-- wide -->
    <svg class="hidden xl:block" viewBox="0 0 1674 1012">
      <g transform="translate(-294)">
        <g transform="translate(-31)">
          <path
            d="M3487.6,2467.18s333.186-451.091,713.4-381.156,538.545,346.154,760,113.79,123.318-527.6,328.183-586.868"
            transform="matrix(0.995, -0.105, 0.105, 0.995, -3417.347, -1058.66)"
            fill="none"
            stroke="#eac072"
            stroke-width="2.5"
          />
          <g transform="translate(213.371 -825)">
            <foreignObject width="463.5" height="309" transform="translate(1006.448 1526)">
              <div class="grid place-items-center" style="width: 464px; height: 309px">
                <AlCmsImageEditor
                  v-model="model.images[3]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 309px; height: 309px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(368.371 -1389)">
            <foreignObject width="403.5" height="269" transform="translate(977.548 1466.404)">
              <div class="grid place-items-center" style="width: 403px; height: 269px">
                <AlCmsImageEditor
                  v-model="model.images[1]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(162.575 -1198)">
            <foreignObject width="345.859" height="233" transform="translate(878.656 1675.041)">
              <div class="grid items-center" style="width: 346px; height: 233px">
                <AlCmsImageEditor
                  v-model="model.images[2]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 200px; height: 200px"
                />
              </div>
            </foreignObject>
          </g>
          <g transform="translate(20.824 -1304)">
            <foreignObject width="267.136" height="400.632" transform="translate(732.176 1453.27)">
              <div class="grid place-items-center" style="width: 267px; height: 401px">
                <AlCmsImageEditor
                  v-model="model.images[0]"
                  :class="IMAGE_EDITOR_CLASS"
                  style="width: 261px; height: 261px"
                />
              </div>
            </foreignObject>
          </g>
          <circle cx="171.5" cy="171.5" r="171.5" transform="translate(1526 358)" fill="#eac072" />
          <circle cx="91.5" cy="91.5" r="91.5" transform="translate(1109 254)" fill="#eac072" />
          <circle cx="138.5" cy="138.5" r="138.5" transform="translate(753 707)" fill="#eac072" />
          <foreignObject
            transform="translate(780 801)"
            width="224"
            height="64"
            class="text-center"
            color="#fff"
            font-size="40"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[2].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(780 863)"
            class="text-center"
            width="224"
            height="40"
            color="#fff"
            font-size="25"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[2].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[2].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(1555 482)"
            class="text-center"
            width="288"
            height="72"
            color="#fff"
            font-size="45"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[1].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(1555 547)"
            class="text-center"
            color="#fff"
            font-size="25"
            width="288"
            height="40"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[1].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ stats[1].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(1131 297)"
            class="text-center"
            color="#fff"
            font-size="45"
            width="136"
            height="72"
            font-family="SecularOne-Regular, Secular One"
          >
            <AlCmsInlineText v-model="model.stats[0].value" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ model.stats[0].value }}</span>
            </AlCmsInlineText>
          </foreignObject>
          <foreignObject
            transform="translate(1131 353)"
            class="text-center"
            width="136"
            height="40"
            color="#fff"
            font-size="25"
            font-family="MartelSans-Regular, Martel Sans"
          >
            <AlCmsInlineText v-model="model.stats[0].label" :class="INLINE_TEXT_EDITOR_CLASS">
              <span>{{ model.stats[0].label }}</span>
            </AlCmsInlineText>
          </foreignObject>
        </g>
      </g>
    </svg>
  </AlPadding>
</template>
