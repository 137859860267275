<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = defineProps<{
  title: string
  text: string
  headerClass?: string
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <AlPadding>
    <div class="mb-8">
      <AlCmsInlineText v-model="model.title" placeholder="Überschrift">
        <h2 :class="twMerge('font-sans-header text-3xl', headerClass)">
          {{ title }}
        </h2>
      </AlCmsInlineText>
    </div>

    <AlCmsRichText
      v-model="model.text"
      class="prose-lg gap-6 font-light leading-7 text-al-gray lg:max-w-[130ch] lg:columns-2"
    />
  </AlPadding>
</template>
