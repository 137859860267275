<script lang="ts" setup>
import type { PUBLIC_IMAGES_BUCKET_NAME } from '~/utils/constants'

const props = defineProps<{
  modelValue: {
    src: string
    alt: string
  }
  bucketName: typeof PUBLIC_IMAGES_BUCKET_NAME
  folderName?: string
  open: boolean
}>()

const isOpen = useVModel(props, 'open')
const user = useUser()
const image = useVModel(props, 'modelValue')
</script>

<template>
  <UModal
    v-model="isOpen"
    fullscreen
    :ui="{
      padding: 'p-0',
    }"
  >
    <div class="overflow-y-auto p-4">
      <div class="mb-4 flex items-center justify-between gap-4">
        <AlMultiImageUploadButton :folder-name="user.id" />
        <UButton color="red" icon="i-ph-x-bold" variant="soft" @click="() => (isOpen = false)" />
      </div>
      <AlCmsMediaCenter
        :hide-upload-button="true"
        selectable
        @image-selected="
          (newImage) => {
            image = newImage
            isOpen = false
          }
        "
      />
    </div>
  </UModal>
</template>
