<script setup lang="ts">
import { useField } from 'vee-validate'

import type { FormGroupType, InputType } from '~/components/inputs/GenericInput.vue'

const props = defineProps<
  {
    name: string
    input?: InputType
    secondary?: boolean
  } & FormGroupType
>()

const { name, input, ...formGroup } = toRefs(props)
const formGroupProps = reactive(formGroup)

const { value, errorMessage } = useField<string | number>(() => props.name)

const inputProps = computed(() => ({
  ...props.input,
  textareaClass: props.secondary ? 'bg-neutral-100 rounded-none ring-0 p-2' : '',
}))
</script>

<template>
  <UFormGroup v-bind="formGroupProps" :name="name" :error="errorMessage">
    <UTextarea
      v-bind="inputProps"
      v-model="value"
      :name="name"
      :rows="5"
      autoresize
      :maxrows="15"
    />
  </UFormGroup>
</template>
