<script setup lang="ts">
import { Bold } from '@tiptap/extension-bold'
import { BulletList } from '@tiptap/extension-bullet-list'
import { Document } from '@tiptap/extension-document'
import { HardBreak } from '@tiptap/extension-hard-break'
import { History } from '@tiptap/extension-history'
import { Italic } from '@tiptap/extension-italic'
import { Link } from '@tiptap/extension-link'
import { ListItem } from '@tiptap/extension-list-item'
import { OrderedList } from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Text } from '@tiptap/extension-text'
import { Typography } from '@tiptap/extension-typography'
import { Underline } from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/vue-3'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  modelValue: string
  placeholder?: string
}>()

const attrs = useAttrs()

const model = useVModel(props, 'modelValue')

const editor = useEditor({
  extensions: [
    Document,
    Text,
    Placeholder.configure({
      placeholder: props.placeholder ?? '',
    }),
    Paragraph,
    Link.configure({
      openOnClick: false,
    }),
    Typography,
    History,
    Bold,
    Italic,
    Underline,
    ListItem,
    BulletList,
    OrderedList,
    HardBreak,
  ],
  editorProps: {
    attributes: {
      ...attrs,
      class:
        `${(attrs.class as string) || ''}` +
        ' ' +
        /* tw */ 'focus:outline-al-primary focus:outline outline-dotted outline-2 outline-stone-300 min-w-[5ch]',
    },
  },

  content: model.value,
  onUpdate({ editor }) {
    model.value = editor.getHTML()
  },
})

watch(model, (newHTML) => {
  if (editor.value?.getHTML() === newHTML) return

  editor.value?.commands.setContent(newHTML, false)
})
</script>

<template>
  <EditorContent :editor="editor" />
</template>

<style lang="postcss" scoped>
:deep(.is-editor-empty:first-child::before) {
  color: theme('colors.stone.300');
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
