<script setup lang="ts">
const props = defineProps<{
  images: {
    src: string
    alt: string
    description: string
  }[]
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <div class="relative">
    <div
      class="absolute -top-32 bottom-1/2 -z-10 flex w-full items-center justify-center max-lg:hidden"
    >
      <AlDecorativeImage
        src="/svg/line_top_left_to_right.svg"
        class="h-full w-full max-w-screen-container"
      />
    </div>
    <div
      class="absolute bottom-[10%] right-0 -z-10 flex h-[45%] w-full items-center justify-center max-lg:hidden"
    >
      <AlDecorativeImage
        src="/svg/line_right_to_bottom_left.svg"
        class="h-full w-full max-w-screen-container"
      />
    </div>

    <AlPadding class="flex flex-col gap-24">
      <template v-for="(image, idx) in model.images" :key="idx">
        <div
          class="relative flex flex-col gap-6"
          :class="[
            idx % 2 === 1 && 'lg:flex-row-reverse lg:text-right',
            idx < 2 && 'lg:flex-row lg:items-end',
          ]"
        >
          <AlCmsImageEditor
            :model-value="image"
            @update:model-value="
              (newImage: any) => {
                image.src = newImage.src
                image.alt = newImage.alt
              }
            "
          />
          <div class="lg:mb-4 lg:self-end">
            <AlCmsRichText v-model="image.description" class="prose-lg font-light leading-7" />
          </div>
        </div>
      </template>
    </AlPadding>
  </div>
</template>
