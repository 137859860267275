<script setup lang="ts">
const props = defineProps<{
  title: string
  src: string
}>()

const { model, editMode } = useCmsMode(props)
</script>

<template>
  <AlPadding class="grid grid-cols-3 gap-8">
    <div v-if="model.title || editMode" class="col-span-full">
      <AlCmsInlineText v-model="model.title" placeholder="Titel">
        <h2 class="font-sans-header text-3xl">
          {{ title }}
        </h2>
      </AlCmsInlineText>
    </div>

    <UFormGroup v-if="editMode" label="iframe URL" class="col-span-full lg:col-span-2">
      <UInput v-model.trim="model.src" type="text" />
    </UFormGroup>

    <iframe
      :src="model.src"
      frameborder="0"
      class="col-span-full mx-auto aspect-video w-full max-w-5xl"
      allowfullscreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
    />
  </AlPadding>
</template>
