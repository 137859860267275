<script lang="ts" setup>
const props = defineProps<{
  entries: {
    time: string
    text: string
  }[]
}>()

const { model, editMode } = useCmsMode(props)
</script>

<template>
  <AlPadding class="flex justify-center">
    <div
      class="inline-grid max-w-screen-container gap-x-40 border-al-primary pl-7 lg:grid-cols-[auto,auto] lg:gap-y-8 lg:border-l-[3px]"
    >
      <div :aria-hidden="true" />
      <h2 class="font-sans-header text-3xl">Vita</h2>
      <template v-for="(entry, idx) of model.entries" :key="idx">
        <AlCmsInlineText v-model="entry.time" placeholder="Zeitraum">
          <span
            :aria-labelledby="`vita-text-${idx}`"
            class="font-sans-header text-2xl text-al-primary max-lg:mt-7"
          >
            {{ entry.time }}
          </span>
        </AlCmsInlineText>
        <div class="flex items-center">
          <AlCmsRichText
            :id="`vita-text-${idx}`"
            v-model="entry.text"
            class="prose-lg flex-1 font-light"
            placeholder="Text"
          />

          <UButton
            v-if="editMode"
            icon="i-ph-trash-fill"
            color="red"
            class="ml-2"
            @click="
              () => {
                model.entries.splice(idx, 1)
              }
            "
          />
        </div>
      </template>
      <AlDashedOutlineButton
        v-if="editMode"
        class="col-span-2"
        @click="
          () =>
            model.entries.push({
              text: '',
              time: new Date().getFullYear().toString(),
            })
        "
      />
    </div>
  </AlPadding>
</template>
