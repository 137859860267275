<script setup lang="ts">
import { Document } from '@tiptap/extension-document'
import { Placeholder } from '@tiptap/extension-placeholder'
import { Text } from '@tiptap/extension-text'
import {
  EditorContent,
  Node,
  NodeViewContent,
  NodeViewWrapper,
  VueNodeViewRenderer,
  mergeAttributes,
  nodeViewProps,
  useEditor,
} from '@tiptap/vue-3'

import type { VNode, VNodeArrayChildren } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  modelValue: string
  placeholder?: string
}>()

const slots = defineSlots<{
  default: () => VNode[]
}>()

const attrs = useAttrs()

const model = useVModel(props, 'modelValue')

const editor = useEditor({
  extensions: [
    Document.extend({ content: 'slot' }),
    Text,
    Placeholder.configure({
      placeholder: props.placeholder ?? '',
    }),
    Node.create({
      name: 'slot',
      group: 'inline',
      content: 'text*',

      parseHTML() {
        return [{ tag: 'slot' }]
      },

      renderHTML({ HTMLAttributes }) {
        return ['slot', mergeAttributes(HTMLAttributes), 0]
      },

      addNodeView() {
        const slot = (slots.default()[0].children as VNodeArrayChildren)[0] as VNode

        return VueNodeViewRenderer(
          defineComponent({
            props: nodeViewProps,
            setup() {
              // <NodeViewWrapper>
              //   <NodeViewContent
              //     as={typeof slot.type === 'string' ? slot.type : 'span'}
              //     {...slot.props}
              //   >
              //     {slot.children}
              //   </NodeViewContent>
              // </NodeViewWrapper>

              return () =>
                h(NodeViewWrapper, {}, () =>
                  h(
                    NodeViewContent,
                    {
                      as: typeof slot.type === 'string' ? slot.type : 'span',
                      ...slot.props,
                    },
                    () => slot.children,
                  ),
                )
            },
          }),
        )
      },
    }),
  ],
  editorProps: {
    attributes: {
      ...attrs,
      class:
        `${(attrs.class as string) || ''}` +
        ' ' +
        /* tw */ 'focus:outline-al-primary focus:outline outline-dotted outline-2 outline-stone-300 min-w-[5ch] w-full' +
        ' ' +
        /* tw */ '!static', // fix bugged editor in Safari (wtf),
    },
  },
  content: model.value,
  onUpdate({ editor }) {
    model.value = editor.getText()
  },
})

watch(model, (newText) => {
  if (editor.value?.getText() === newText) return

  editor.value?.commands.setContent(newText, false)
})
</script>

<template>
  <EditorContent :editor="editor" />
</template>

<style lang="postcss" scoped>
:deep(.is-editor-empty:first-child::before) {
  color: theme('colors.stone.300');
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
</style>
