<script setup lang="ts">
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import type { Swiper as SwiperType } from 'swiper'

import 'swiper/css'
import 'swiper/css/navigation'

interface Image {
  src: string
  alt: string
}
const props = defineProps<{
  images: Image[]
}>()

const { model, editMode } = useCmsMode(props)

const swiper = shallowRef<SwiperType>()
</script>

<template>
  <AlPadding side="left">
    <div class="relative flex gap-6">
      <p
        style="writing-mode: vertical-lr"
        class="impression-header mr-4 rotate-180 self-end text-4xl font-extrabold uppercase text-white transition-colors group-hover:text-al-primary lg:text-6xl"
      >
        Impressionen
      </p>
      <Swiper
        :modules="[Navigation, A11y]"
        slides-per-view="auto"
        :space-between="50"
        :navigation="{ disabledClass: 'hidden after:hidden pointer-events-none' }"
        :allow-touch-move="true"
        class="relative !mx-0 h-[20rem] flex-1 select-none justify-self-end lg:h-[30rem]"
        @swiper="(_swiper) => (swiper = _swiper)"
      >
        <SwiperSlide
          v-for="(image, idx) in model.images"
          :key="`${image.src}${idx}`"
          class="!w-auto"
        >
          <AlCmsImageEditor
            show-delete-button
            :model-value="image"
            class="h-full"
            @delete="() => model.images.splice(idx, 1)"
            @update:model-value="
              (newImage: any) => {
                image.src = newImage.src
                image.alt = newImage.alt
              }
            "
          />
        </SwiperSlide>
      </Swiper>

      <AlDashedOutlineButton
        v-if="editMode"
        @click="
          () => {
            model.images.push({ alt: '', src: IMAGE_PLACEHOLDER })
            nextTick(() => {
              swiper?.slideTo(swiper.slides.length - 1)
            })
          }
        "
      />
    </div>
  </AlPadding>
</template>

<style scoped>
.impression-header {
  -webkit-text-stroke: 0.02em rgb(226 193 125);
}
</style>
