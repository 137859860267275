<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useForm } from 'vee-validate'

import { atelierInquirySchema } from '~/utils/schemas'

const { meta, values, setFieldValue, handleSubmit, handleReset, errors, resetForm } = useForm({
  validationSchema: toTypedSchema(atelierInquirySchema),
  initialValues: {
    dateRange: [],
    name: '',
    email: '',
    message: '',
    privacy: false,
  },
})

const toast = useToast()

const artistSlugId = computed(() => useSlugId().value._unsafeUnwrap())

const submit = handleSubmit(async (v) => {
  useTRPC()
    .contact.atelierInquiry.mutate({ ...v, artistSlug: artistSlugId.value })
    .then(() => {
      toast.add({
        color: 'green',
        title: 'Anfrage gesendet',
        description: 'Wir werden uns so schnell wie möglich bei Ihnen melden.',
      })
      resetForm()
    })
    .catch((err) => {
      toast.add({
        color: 'red',
        title: 'Nachricht konnte nicht gesendet werden.',
        description: err.message,
      })
    })
})
</script>

<template>
  <AlPadding limit-width>
    <h2 class="col-span-2 mb-12 text-3xl font-bold">Atelier mieten</h2>
    <div class="mx-auto grid max-w-4xl grid-cols-2 grid-rows-[1fr,auto] gap-12">
      <form
        class="contents"
        @submit.prevent="(event) => submit(event)"
        @reset.prevent="() => handleReset()"
      >
        <UFormGroup name="dateRange" :error="meta.dirty && errors.dateRange" class="content-center">
          <AlDateRangePicker
            :model-value="values.dateRange"
            inline
            auto-apply
            @update:model-value="(value) => setFieldValue('dateRange', value ?? undefined)"
          />
        </UFormGroup>
        <div class="flex flex-col gap-8">
          <AlTextInput name="name" label="Name" secondary required />
          <AlTextInput name="email" :input="{ type: 'email' }" label="E-Mail" secondary required />
          <AlTextAreaInput name="message" label="Kommentar" secondary />
          <UFormGroup name="privacy" :error="meta.dirty && errors.privacy">
            <UCheckbox
              :model-value="values.privacy"
              label="Einverständnis zur Verarbeitung Ihrer Daten"
              required
              @update:model-value="(value) => setFieldValue('privacy', value)"
            />
          </UFormGroup>
        </div>
        <div class="col-start-2">
          <AlSlideoutButton type="submit" variant="secondary">Anfragen</AlSlideoutButton>
        </div>
      </form>
    </div>
  </AlPadding>
</template>
