import type { Json } from '~/supabase.db'

export const IMAGE_PLACEHOLDER = '/svg/placeholder.svg'
export const CMS_BLOCKS = {
  IFrame: defineCmsBlock({
    name: 'IFrame',
    component: () => resolveComponent('AlIFrame'),
    defaultProps: {
      src: 'https://www.youtube-nocookie.com/embed/',
    },
    sanitize(props) {
      return props
    },
    thumbnailSrc: '/cms-preview/iframe.png',
  }),

  InfoBubbles: defineCmsBlock({
    name: 'Info Bubbles',
    component: () => resolveComponent('AlInfoBubbles'),
    defaultProps: {
      images: Array.from({ length: 4 }, () => ({
        src: IMAGE_PLACEHOLDER,
        alt: '',
      })),
      stats: [
        {
          label: 'Wert 1',
          value: '9.234',
        },
        {
          label: 'Wert 2',
          value: '1.234',
        },
        {
          label: 'Wert 3',
          value: '234.986',
        },
      ],
    },
    sanitize(props) {
      return props
    },
    thumbnailSrc: '/cms-preview/info-bubbles.png',
  }),

  OfferBooking: defineCmsBlock({
    static: true,
    name: 'Offer Booking',
    component: () => resolveComponent('AlOfferBooking'),
    defaultProps: {
      title: 'Über dieses Angebot',
      text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua',
      offerSlugId: '',
    },
    sanitize(props, sanitizeHtml) {
      return {
        title: sanitizeHtml(props.title),
        text: sanitizeHtml(props.text),
        offerSlugId: props.offerSlugId,
      }
    },
    thumbnailSrc: IMAGE_PLACEHOLDER,
  }),

  TimelineSection: defineCmsBlock({
    name: 'Zeitstrahl',
    component: () => resolveComponent('AlTimelineSection'),
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        entries: props.entries.map((entry) => ({
          ...entry,
          text: sanitizeHtml(entry.text),
        })),
      }
    },
    thumbnailSrc: '/cms-preview/timeline.png',
    defaultProps: {
      entries: [
        {
          time: '2023',
          text: 'Launch von Atelierluft',
        },
      ],
    },
  }),

  ImpressionsSection: defineCmsBlock({
    name: 'Impressionen',
    component: () => resolveComponent('AlImpressionsSection'),
    sanitize(props) {
      return props
    },
    thumbnailSrc: '/cms-preview/impressions.png',
    defaultProps: {
      images: [] as string[],
    },
  }),

  Text: defineCmsBlock({
    name: 'Text',
    component: () => resolveComponent('AlText'),
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        text: sanitizeHtml(props.text),
      }
    },
    thumbnailSrc: '/cms-preview/text.png',
    defaultProps: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
  }),

  TwoColumnText: defineCmsBlock({
    name: 'Zwei Spalten Text',
    component: () => resolveComponent('AlTwoColumnText'),
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        text: sanitizeHtml(props.text),
      }
    },
    thumbnailSrc: '/cms-preview/two-column-text.png',
    defaultProps: {
      title: 'Geschichte',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    },
  }),

  InfoBulletpoints: defineCmsBlock({
    name: 'Info Stichpunkte',
    component: () => resolveComponent('AlInfoBulletpoints'),
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        infos: props.infos.map((info) => ({
          ...info,
          text: sanitizeHtml(info.text),
        })),
      }
    },
    defaultProps: {
      title: 'Weitere Informationen',
      infos: [
        {
          text: 'Information 1',
        },
        {
          text: 'Information 2',
        },
        {
          text: 'Information 3',
        },
      ],
    },
    thumbnailSrc: '/cms-preview/info-bullet-points.png',
  }),

  PresentationSection: defineCmsBlock({
    name: 'Präsentation',
    component: () => resolveComponent('AlPresentationSection'),
    defaultProps: {
      title: 'Vorstellung',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Massa massa ultricies mi quis hendrerit dolor. In hac habitasse platea dictumst. Bibendum arcu vitae elementum curabitur vitae nunc sed velit. Sit amet dictum sit amet justo. Nunc id cursus metus aliquam eleifend mi in nulla posuere. Sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae nunc. Ac feugiat sed lectus vestibulum mattis ullamcorper velit sed. In hac habitasse platea dictumst quisque sagittis purus. Tincidunt dui ut ornare lectus sit amet est. Nunc sed id semper risus in hendrerit gravida rutrum quisque. Imperdiet proin fermentum leo vel.\n Vestibulum lectus mauris ultrices eros in cursus turpis massa. Dolor sit amet consectetur adipiscing. Fringilla urna porttitor rhoncus dolor. Tristique sollicitudin nibh sit amet commodo nulla. Luctus venenatis lectus magna fringilla urna porttitor rhoncus dolor purus. Amet consectetur adipiscing elit pellentesque habitant morbi. Aliquam eleifend mi in nulla posuere. Velit laoreet id donec ultrices tincidunt arcu. Non odio euismod lacinia at quis risus. Aliquam ut porttitor leo a diam sollicitudin tempor id eu.',
      image: {
        src: IMAGE_PLACEHOLDER,
        alt: '',
      },
    },
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        title: sanitizeHtml(props.title),
        text: sanitizeHtml(props.text),
      }
    },
    thumbnailSrc: '/cms-preview/presentation.png',
  }),

  IntroSection: defineCmsBlock({
    name: 'Intro',
    component: () => resolveComponent('AlIntroSection'),
    defaultProps: {
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed elementum dolor. Nulla urna lorem, sagittis eu nisl ut, placerat tempus sapien. Vivamus nec tempus ante, sit amet gravida quam. Proin at justo faucibus, ultricies elit vel, maximus tortor. Praesent nisi nibh, sodales vitae neque a, gravida pulvinar orci. Quisque congue magna tempor metus dictum, vitae facilisis ante vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque mattis accumsan bibendum. Suspendisse potenti. Nulla nec vehicula ligula. Praesent tincidunt fermentum sapien eget maximus. Nulla vitae vulputate tortor.',
    },
    sanitize(props, sanitizeHtml) {
      return {
        text: sanitizeHtml(props.text),
      }
    },
    thumbnailSrc: '/cms-preview/intro.png',
  }),

  ImageTextSection: defineCmsBlock({
    name: 'Bilder mit Text',
    component: () => resolveComponent('AlImageTextSection'),
    defaultProps: {
      images: Array.from({ length: 3 }, () => ({
        src: IMAGE_PLACEHOLDER,
        alt: '',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sed elementum dolor. Nulla urna lorem, sagittis eu nisl ut, placerat tempus sapien. Vivamus nec tempus ante, sit amet gravida quam. Proin at justo faucibus, ultricies elit vel, maximus tortor. Praesent nisi nibh, sodales vitae neque a, gravida pulvinar orci. Quisque congue magna tempor metus dictum, vitae facilisis ante vehicula. Interdum et malesuada fames ac ante ipsum primis in faucibus. Quisque mattis accumsan bibendum. Suspendisse potenti. Nulla nec vehicula ligula. Praesent tincidunt fermentum sapien eget maximus. Nulla vitae vulputate tortor.',
      })),
    },
    sanitize(props, sanitizeHtml) {
      return {
        ...props,
        images: props.images.map((image) => ({
          ...image,
          description: sanitizeHtml(image.description),
        })),
      }
    },
    thumbnailSrc: '/cms-preview/image-text.png',
  }),

  BookAtelierForm: defineCmsBlock({
    name: '"Atelier mieten" Formular',
    component: () => resolveComponent('AlBookAtelierForm'),
    defaultProps: {},
    sanitize(props) {
      return props
    },
    thumbnailSrc: '/cms-preview/book-atelier-form.png',
  }),
} satisfies Record<string, CmsBlock>

export type CmsBlock<T extends Json = any> = {
  name: string
  thumbnailSrc: string
  sanitize: (props: T, sanitizeHtml: (html: string) => string) => T
  component: () => ReturnType<typeof resolveComponent>
  defaultProps: T
  static?: boolean
}

export type CmsBlocksKey = keyof typeof CMS_BLOCKS

function defineCmsBlock<T extends Json>(component: CmsBlock<T>) {
  return component
}
