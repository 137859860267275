<script setup lang="ts">
const props = defineProps<{
  modelValue: string
  placeholder?: string
}>()

defineSlots<{
  default: void
}>()

const model = useVModel(props, 'modelValue')

const editMode = useEditMode()
</script>

<template>
  <AlCmsInlineTextEditor v-if="editMode" v-bind="props" v-model="model">
    <slot>No slot provided</slot>
  </AlCmsInlineTextEditor>
  <slot v-else>No slot provided</slot>
</template>
