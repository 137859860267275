<script setup lang="ts">
const props = defineProps<{
  title: string
  text: string
  image: {
    src: string
    alt: string
  }
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <div class="relative">
    <div
      class="absolute -top-16 bottom-1/3 -z-10 flex w-full items-center justify-center max-lg:hidden"
    >
      <AlDecorativeImage
        src="/svg/line_bottom_left_to_top_right.svg"
        class="h-full w-full max-w-screen-container"
      />
    </div>

    <AlPadding side="left" class="flex flex-col gap-8">
      <div class="relative @container">
        <AlCmsImageEditor
          :model-value="model.image"
          editor-class="mx-auto md:ml-8 md:mb-8 md:float-right w-[50%]"
          :editor-style="`shape-margin: 2rem; shape-outside: url(${model.image.src})`"
          @update:model-value="
            (newImage: any) => {
              model.image.src = newImage.src
              model.image.alt = newImage.alt
            }
          "
        />
        <div class="mt-8 xl:mt-80">
          <AlCmsInlineText v-model="model.title">
            <h2
              class="mb-4 mt-0 whitespace-nowrap font-sans-header text-3xl text-al-primary max-md:text-center md:mb-8 lg:text-5xl xl:text-6xl"
            >
              {{ model.title }}
            </h2>
          </AlCmsInlineText>
          <AlCmsRichText
            v-model="model.text"
            class="text-lg leading-9 text-stone-500 lg:max-w-[40vw]"
          />
        </div>
      </div>
    </AlPadding>
  </div>
</template>
