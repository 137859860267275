<script setup lang="ts">
const props = defineProps<{
  title: string
  text: string
  offerSlugId: string
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <AlPadding class="grid gap-16 lg:grid-cols-2">
    <div>
      <AlCmsInlineText v-model="model.title">
        <h3 class="mb-6 font-sans-header text-3xl uppercase text-al-primary">
          {{ title }}
        </h3>
      </AlCmsInlineText>
      <AlCmsRichText
        v-model="model.text"
        class="text-lg font-light lg:max-w-[60ch]"
        placeholder="Beschreibung"
      />
    </div>

    <div class="mx-auto mt-8 max-w-xl rounded-3xl px-6 py-8 shadow-floating sm:px-8 sm:py-10">
      <div class="flex h-full flex-col gap-5">
        <h3 class="font-sans-header text-3xl uppercase text-al-primary">Buchung</h3>
        <AlAppointmentStep embedded :offer-slug-id="offerSlugId" />
      </div>
    </div>
  </AlPadding>
</template>
