<script setup lang="ts">
const props = defineProps<{
  text: string
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <AlPadding class="space-y-6 lg:space-y-12">
    <div class="flex items-end gap-6">
      <h2 class="font-sans-header text-4xl/snug lg:text-6xl/snug">
        Herzlich
        <br />
        will&shy;kommen
      </h2>
      <img
        src="/logo_md.webp"
        class="mb-6 ml-auto h-48 w-auto object-contain max-md:hidden lg:h-64 xl:ml-60"
      />
    </div>
    <AlCmsRichText v-model="model.text" />
  </AlPadding>
</template>
