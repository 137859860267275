<script setup lang="ts">
const props = defineProps<{
  text: string
}>()

const { model } = useCmsMode(props)
</script>

<template>
  <AlPadding>
    <AlCmsRichText v-model="model.text" />
  </AlPadding>
</template>
